<template>
  <a-skeleton v-if="loading" active style="max-width: 800px" />
  <a-result
    v-else-if="!definitions.length"
    status="404"
    sub-title="未找到相关连接器"
  />
  <div v-else class="list-wrapper">
    <div
      v-for="(item, index) in definitions"
      :key="index"
      :class="['list-item', getItemClass(item)]"
      @click="$emit('select', item)"
    >
      <img :src="item.icon" />
      <span class="title">{{ item.name }}</span>
      <slot name="extra" :item="item">
        <div class="overlay">
          <i class="ndl-icon-plus"></i>
          <span>添加</span>
        </div>
      </slot>
    </div>
    <!-- 占位元素，用于左对齐。每行最多 4 个，因此用 3 个占位元素 -->
    <span class="placeholder"></span>
    <span class="placeholder"></span>
    <span class="placeholder"></span>
  </div>
</template>

<script>
import { string } from "@/utils/props-validator";
import { definitionList } from "@/views/connections/models/definitions";
import { Types } from "@/views/connections/models/definitions";

export default {
  props: {
    category: string.enum(Types.SOURCE, Types.DESTINATION),
    search: String,
    // 可以是 string|object|array|function
    itemClass: null
  },
  computed: {
    loading() {
      return definitionList.loading;
    },
    definitions() {
      let list = [];
      switch (this.category) {
        case Types.SOURCE:
          list = definitionList.sources;
          break;
        case Types.DESTINATION:
          list = definitionList.destinations;
          break;
        default:
          break;
      }
      const qs = String(this.search || "").toLowerCase();
      if (!qs) {
        return list;
      }
      return list.filter(item => {
        const name = item.name.toLowerCase();
        return name.indexOf(qs) >= 0;
      });
    }
  },
  methods: {
    getItemClass(item) {
      const itemClass = this.itemClass;
      if (typeof itemClass === "function") {
        return itemClass.call(this, item);
      }
      return itemClass;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/connections/assets/vars.less";

@width: 180px;
@height: 120px;
@gutter: 20px;

.list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  max-width: 920px;
}
.placeholder {
  width: @width;
  height: 0;
  margin: 0 @gutter;
}
.list-item {
  width: @width;
  height: @height;
  margin: @gutter;
  padding: 12px;
  border: solid 1px #ebebeb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  img {
    height: 45px;
    width: 45px;
  }
  .title {
    margin-top: 10px;
  }
  .title,
  .overlay {
    color: @local-title-color;
    text-align: center;
  }
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(215, 215, 215, 0.92);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-size: 18px;
    transition: opacity 0.2s;
    i {
      font-size: 26px;
      margin-bottom: 6px;
    }
  }
  &:hover .overlay {
    opacity: 1;
  }
}
</style>
