export function oneof(...params) {
  const values = [].concat(...params);
  return val => values.indexOf(val) >= 0;
}

export const string = {
  enum(...params) {
    return {
      type: String,
      validator: oneof(...params)
    };
  }
};
